<template>
	<section class="contact-grid">
		<div class="contact-grid-reda">
			<h1 class="contact-h1">Reda</h1>
			<h3>
				{{$t('contact.ul') }} Pucka 59 <br />
				84-240 Reda <br />
				(22) 208 73 18
			</h3>
		</div>
		<div class="contact-grid-warszawa">
			<!-- <h1 class="contact-h1">Warszawa</h1>
            <h3>ul. Sowińskiego 53C/15 <br> 01-105 Warszawa <br> (22) 208 73 18</h3> -->
		</div>
		<div class="contact-grid-info">
			<h3>
				<span v-html="$t('contact.grid')"></span>
			</h3>
			<h3>info@etrustmedical.com</h3>
		</div>
		<div class="contact-grid-photo"></div>
	</section>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/config", "~@/assets/scss/media-queries", "~@/assets/scss/default-styles";

.contact-grid {
	padding: 85px 0 220px 0;
	display: grid;
	grid-template-columns: 600px 600px;
	grid-template-rows: 600px 600px;
	justify-content: center;
	div {
		padding: 36px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		h3 {
			color: white;
			font-weight: lighter;
			line-height: 36.3px;
			letter-spacing: -1.12px;
			font-size: $fontSize28;
		}
	}
	&-reda {
		background-color: $light-bluev2;
	}
	&-warszawa {
		background-color: $primary-blue;
	}
	&-info {
		background-color: $dark-blue;
	}
	&-photo {
		background: url("~@/assets/images/mariusz-budzisz.jpg") no-repeat center;
        background-size: cover;
	}
	@include respond-below(xl) {
		grid-template-columns: 42.5vw 42.5vw;
		grid-template-rows: 42.5vw 42.5vw;
	}
	@include respond-below(lg) {
		grid-template-columns: 42.5vw 42.5vw;
		grid-template-rows: 42.5vw 42.5vw;
		div {
			h3 {
				font-size: $fontSize24;
			}
		}
	}
	@include respond-below(md) {
		grid-template-columns: 42.5vw 42.5vw;
		grid-template-rows: 42.5vw 42.5vw;
		div {
			padding: 22px;
			h1 {
				font-size: $fontSize32;
			}
			h3 {
				font-size: $fontSize18;
				line-height: normal;
			}
		}
	}
	@include respond-below(sm) {
		padding: 30px 0 75px 0;
		grid-template-columns: 85vw;
		grid-template-rows: repeat(3, 85vw);
		div {
			padding: 24px 0 36px 28px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			h3 {
				color: white;
				font-weight: lighter;
				line-height: 19.2px;
				letter-spacing: -0.6px;
				width: 90%;
				font-size: $fontSize15;
			}
		}
		.contact-grid-photo {
			display: none;
		}
	}
}
</style>
