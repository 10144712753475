<template>
	<section class="products-overview">
		<div class="products-overview-grid">
			<div class="products-overview-grid-content">
				<h3>{{ $t("AboutUsGrid.1.title") }}</h3>
				<p>
					{{ $t("korzysc") }}<br /><br />
					{{ $t("AboutUsGrid.1.content") }}
				</p>
				<img src="~@/assets/images/icons/burger.svg" alt="BanAir" />
			</div>
			<div class="products-overview-grid-content">
				<h3>{{ $t("AboutUsGrid.2.title") }}</h3>
				<p>
					{{ $t("korzysc") }}<br /><br />
					{{ $t("AboutUsGrid.2.content") }}
				</p>
				<img src="~@/assets/images/icons/chart.svg" alt="BanAir" />
			</div>
			<div class="products-overview-grid-content">
				<h3>{{ $t("AboutUsGrid.3.title") }}</h3>
				<p>
					{{ $t("korzysc") }}<br /><br />
					{{ $t("AboutUsGrid.3.content") }}
				</p>
				<img src="~@/assets/images/icons/document.svg" alt="BanAir" />
			</div>
			<div class="products-overview-grid-content">
				<h3>{{ $t("AboutUsGrid.4.title") }}</h3>
				<p>
					{{ $t("korzysc") }}<br /><br />
					{{ $t("AboutUsGrid.4.content") }}
				</p>
				<img src="~@/assets/images/icons/gear.svg" alt="BanAir" />
			</div>
			<div class="products-overview-grid-content"></div>
			<div class="products-overview-grid-content">
				<h3>{{ $t("AboutUsGrid.5.title") }}</h3>
				<p>
					{{ $t("korzysc") }}<br /><br />
					{{ $t("AboutUsGrid.5.content") }}
				</p>
				<img src="~@/assets/images/icons/bulb.svg" alt="BanAir" />
			</div>
			<div class="products-overview-grid-content">
				<h3>{{ $t("AboutUsGrid.6.title") }}</h3>
				<p>
					{{ $t("korzysc") }}<br /><br />
					{{ $t("AboutUsGrid.6.content") }}
				</p>
				<img src="~@/assets/images/icons/personcircle.svg" alt="BanAir" />
			</div>
			<div class="products-overview-grid-content">
				<h3>{{ $t("AboutUsGrid.7.title") }}</h3>
				<p>
					{{ $t("korzysc") }}<br /><br />
					{{ $t("AboutUsGrid.7.content") }}
				</p>
				<img src="~@/assets/images/icons/arrow.svg" alt="BanAir" />
			</div>
			<div class="products-overview-grid-content">
				<h3>{{ $t("AboutUsGrid.8.title") }}</h3>
				<p>
					{{ $t("korzysc") }}<br /><br />
					{{ $t("AboutUsGrid.8.content") }}
				</p>
				<img src="~@/assets/images/icons/chartonhand.svg" alt="BanAir" />
			</div>
		</div>
	</section>
</template>
