<template>
	<h1>{{ $t("co jest") }}</h1>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/config", "~@/assets/scss/media-queries", "~@/assets/scss/default-styles";
h1 {
	margin: 100px auto;
	color: $primary-blue;
	text-align: center;
	width: 100%;
	@include respond-below(lg) {
		margin: 55px auto;
	}
}
</style>
