<template>
	<section class="about-us">
		<div class="about-us-container">
			<h3>
				{{ $t("AboutUsTeam.nasz") }}<br class="mobile-br" />
				{{ $t("AboutUsTeam.kompetencje") }}<br class="mobile-br" />
				{{ $t("AboutUsTeam.procesami") }}<br class="mobile-br" />
				{{ $t("AboutUsTeam.oprogramowaniem") }} <br /><br />
                {{ $t("AboutUsTeam.pracujemy") }}<br />
                {{ $t("AboutUsTeam.juz") }}
			</h3>
			<button class="btn button-white" @click="$router.push('team')">
				<p>{{ $t("AboutUsTeam.o") }}</p>
			</button>
		</div>
		<div class="about-us-picture"></div>
	</section>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/config", "~@/assets/scss/media-queries", "~@/assets/scss/default-styles";

.about-us {
	display: grid;
	grid-template-columns: 600px 600px;
	grid-template-rows: 600px;
	justify-content: center;
	padding: 200px 0 220px 0;
	&-container {
		background-color: $light-blue;
		padding: 42px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		h3 {
			color: white;
			letter-spacing: -1.12px;
			line-height: 36.3px;
			width: 330px;
		}
	}
	&-picture {
		background-image: url("~@/assets/images/teamcontact.jpg");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}
	@include respond-below(xl) {
		grid-template-columns: 45vw 45vw;
		grid-template-rows: 45vw;
		&-container {
			padding: 32px;
		}
	}
	@include respond-below(lg) {
		padding-bottom: 60px;
		grid-template-columns: minmax($mobile-grid-width-min, $mobile-grid-width-max);
		grid-template-rows:
			auto
			minmax($mobile-grid-width-min, $mobile-grid-width-max);
		&-container {
			padding: 32px;
			h3 {
				font-size: $fontSize24;
				letter-spacing: -0.6px;
				width: 60%;
				line-height: normal;
				.desktop-br {
					display: none;
				}
			}
			.btn {
				margin-top: 32px;
			}
		}
	}
	@include respond-below(md) {
		&-container {
			h3 {
				font-size: $fontSize20;
			}
		}
	}
	@include respond-below(sm) {
		padding: 93px 0 60px 0;
		grid-template-rows: repeat(2, minmax($mobile-grid-width-min, $mobile-grid-width-max));
		&-container {
			padding: 22px;
			h3 {
				font-size: $fontSize15;
				letter-spacing: -0.6px;
				width: 70%;
				line-height: 18px;
				font-family: "Roobert-light";
				font-weight: lighter;
			}
			.btn {
				margin-top: 0;
			}
		}
	}
}
</style>
