<template>
    <section class="nav-info" id="nav-switch">
        <p>
            <span v-if="$route.name != 'home'" @click="$router.push('/')">Home - </span>
            <span v-if="$route.path.includes('/products')" @click="$router.push('/products')">{{$t('breadcrumbs.products')}}</span>
            <span v-if="$route.path.includes('/team')" @click="$router.push('/team')">{{$t('breadcrumbs.team')}}</span>
            {{ currentPath($route.name) }}
        </p>
    </section>
</template>

<script>
export default {
    methods: {
        currentPath(path) {
            if (path === "sorban") {
                return this.$t(`breadcrumbs.${path}`);
            } else if (path === "radban") {
                return this.$t(`breadcrumbs.${path}`);
            } else if (path === "ecrf") {
                return this.$t(`breadcrumbs.${path}`);
            } else if (path === "hospanel") {
                return this.$t(`breadcrumbs.${path}`);
            } else if (path === "specific") {
                return this.$t(`breadcrumbs.${path}`);
            } else if (path === "optimalization") {
                return this.$t(`breadcrumbs.${path}`);
            } else if (path === "contact") {
                return this.$t(`breadcrumbs.${path}`);
            } else if (path === "about-us") {
                return this.$t(`breadcrumbs.${path}`);
            } else if (path === "mariusz-budzisz") {
                return this.$t(`breadcrumbs.${path}`);
            } else if (path === "michal-kasiewicz") {
                return this.$t(`breadcrumbs.${path}`);
            } else if (path === "pawel-gawronski") {
                return this.$t(`breadcrumbs.${path}`);
            } else if (path === "privacy-policy") {
                return this.$t(`breadcrumbs.${path}`);
            } else if (path === "not-found") {
                return this.$t(`breadcrumbs.${path}`);
            }
        },
    },
};
</script>

<style scoped>
p {
    cursor: pointer;
}
</style>
