<template>
    <section class="about-title">
        <h1>
            {{ $t('proces pracy')}}<br class="mobile-br" />
            {{$t('krok po kroku')}}
        </h1>
    </section>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/config", "~@/assets/scss/media-queries",
    "~@/assets/scss/default-styles";

.about-title {
    h1 {
        color: $primary-blue;
        letter-spacing: -2.48px;
        line-height: 73px;
        text-align: center;
        margin: 99px auto 80px auto;
    }
    @include respond-below(md) {
        h1 {
            width: 85vw;
            .mobile-br {
                display: block;
            }
        }
    }
    @include respond-below(sm) {
        h1 {
            line-height: 34.64px;
            width: auto;
            margin: 55px auto 63px auto;
            letter-spacing: -1.12px;
            text-align: center;
        }
    }
}
</style>
