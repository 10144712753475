<template>
    <section class="contact-grid">
        <div class="contact-grid-info">
            <h3>
                <span v-html="$t('contactGrid')"></span>
            </h3>
            <h3>info@etrustmedical.com</h3>
        </div>
        <div class="contact-grid-photo"></div>
    </section>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/config", "~@/assets/scss/media-queries",
    "~@/assets/scss/default-styles";

.contact-grid {
    display: grid;
    grid-template-rows: 600px;
    grid-template-columns: repeat(2, 600px);
    justify-content: center;
    color: white;
    margin: 110px 0 220px 0;
    h3 {
        letter-spacing: -1.12px;
        line-height: 36.3px;
        &:nth-child(2) {
            margin-top: 30px;
        }
    }
    &-info {
        background-color: $primary-blue;
        padding: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    &-photo {
        background-image: url("~@/assets/images/mariusz-budzisz.jpg");
        background-repeat: no-repeat;
        background-size: cover;
    }
    @include respond-below(xl) {
        grid-template-columns: repeat(2, 45vw);
        grid-template-rows: 45vw;
    }
    @include respond-below(lg) {
        grid-template-columns: 85vw;
        grid-template-rows: 85vw;
        .contact-grid-photo {
            display: none;
        }
    }
    @include respond-below(sm) {
        grid-template-columns: minmax(
            $mobile-grid-width-min,
            $mobile-grid-width-max
        );
        grid-template-rows: minmax(
            $mobile-grid-width-min,
            $mobile-grid-width-max
        );
        margin: 0 auto 60px auto;
        &-info {
            padding: 32px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        h3 {
            font-size: $fontSize15;
            letter-spacing: -0.6px;
            line-height: 18px;
        }
    }
}
</style>
