<template>
	<section class="products-banair">
		<div class="products-banair-title">
			<h1>
				{{ $t("Produkty.AirBanGal.title") }} <b>AirBan™</b><br />
				{{ $t("Produkty.AirBanGal.title2") }}
			</h1>
			<img src="~@/assets/images/b_a-circleicon.svg" alt="BanAir" />
		</div>
		<div class="products-banair-content">
			<div class="content-wrapper">
				<h2>
					{{ $t("Produkty.AirBanGal.contentUp") }}
				</h2>
				<h2><b>AirBan™</b> {{ $t("Produkty.AirBanGal.contentDown") }}</h2>
			</div>
			<button class="btn button-white" @click="this.$router.push({ name: 'products', hash: '#AirBan' })">
				<span v-html="$t('Produkty.AirBanGal.button')"></span>
			</button>
		</div>
	</section>
</template>

<style scoped>
a {
	color: white;
}
</style>
