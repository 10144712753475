<template>
	<section class="cookies">
		<div>
			<p>
				<span v-html="$t('cookies.content')"></span>
				<span class="underline" @click="$router.push('privacy-policy')">{{ $t("cookies.polityka") }}</span>
			</p>
			<button class="btn button-blue" @click="$emit('acceptCookies')">
				<span class="desktop-p">Ok</span><span class="mobile-p">Akceptuje</span>
			</button>
		</div>
	</section>
</template>

<script>
export default {
	emits: ["acceptCookies"],
};
</script>
