<template>
    <section class="about-implementations">
        <div>
            <h1 class="anim">
                <div class="div-anim" data-delay=".1s">{{ $t('AboutUsTworzymy.tworzymy') }}&nbsp;</div>
                <div class="div-anim" data-delay=".3s">{{ $t('AboutUsTworzymy.rozwiazania') }}</div>
                <br />
                <div class="div-anim" data-delay=".3s">{{ $t('AboutUsTworzymy.ktore') }}</div>
                <br />
                <div class="div-anim" data-delay=".5s">{{ $t('AboutUsTworzymy.i') }}</div>
            </h1>
        </div>
        <div v-if="$i18n.locale === 'en-US'">
			<button style="width: 290px" class="btn button-wide" @click="$router.push('/products')">
				{{ $t("AboutUsTworzymy.button") }}
			</button>
		</div>
		<div v-if="$i18n.locale === 'pl-PL'">
			<button class="btn button-wide" @click="$router.push('/products')">{{ $t("AboutUsTworzymy.button") }}</button>
		</div>
    </section>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/config", "~@/assets/scss/media-queries", "~@/assets/scss/default-styles";

.about-implementations {
    background-image: url("~@/assets/images/signing-papers.png");
    height: 100vh;
    @include flex-center-column();
    h1 {
        width: auto;
        line-height: 62px;
        letter-spacing: 0;
        text-align: center;
    }
    div:nth-child(2) {
        .btn {
            margin: 40px auto 0 auto;
        }
    }
    @include respond-below(lg) {
        background: none;
        height: auto;
        div:first-child {
            background-image: url("~@/assets/images/signing-papers.png");
            background-size: cover;
            width: 100%;
            height: 55vw;
            h1 {
                display: none;
            }
        }
        div:nth-child(2) {
            background-color: $background-whiteish;
            width: 100%;
            text-align: center;
        }
    }
}
</style>
