<template>
	<section class="home-products">
		<div class="home-products-grid">
			<div
				class="home-products-grid-korban"
				:class="selected === product.id ? 'active' : null"
				v-for="product in func"
				:key="product.id"
				:id="product.animationId"
				@click="$router.push(`/products/${product.id}`)"
				@mouseover="playVideo(product.id, true), (selected = product.id), hover(product.animationId)"
				@mouseleave="playVideo(product.id, false), (selected = null), unhover()"
			>
				<div class="home-products-grid-korban-up">
					<h3>{{ $t(`Produkty.names.${product.id}`) }}</h3>
					<p style="white-space: pre">
						{{ $t(`Produkty.${product.id}`) }}
					</p>
				</div>
				<div class="home-products-grid-korban-bottom">
					<video muted loop playsinline class="products-video" :id="product.id">
						<source :src="require(`@/assets/videos/400x200/${product.videoSource}`)" type="video/mp4" />
					</video>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	data() {
		return {
			products: [
				{
					id: "sorban",
					title: "SorBan™",
					videoSource: "korbansmall.mp4",
					animationId: "anim1",
				},
				{
					id: "radban",
					title: "RadBan™",
					videoSource: "bannersmall.mp4",
					animationId: "anim2",
				},
				{
					id: "hospanel",
					title: "HosPanel™",
					videoSource: "hospanelsmall.mp4",
					animationId: "anim3",
				},
				{
					id: "ecrf",
					title: "eCRF",
					videoSource: "ecrfsmall.mp4",
					animationId: "anim4",
				},
				{
					id: "specific",
					title: "Systemy dedykowane",
					videoSource: "specificsmall.mp4",
					animationId: "anim5",
				},
				{
					id: "optimalization",
					title: "Optymalizacja procesów",
					videoSource: "optimalization.mp4",
					animationId: "anim6",
				},
			],
			selected: null,
		};
	},
	methods: {
		playVideo(id, play) {
			if (this.screenWidth < 1200) return;
			const video = document.getElementById(id);
			if (video) {
				if (play) {
					video.play();
				} else {
					video.pause();
				}
			}
		},
		hover(id) {
			let elements = document.getElementsByClassName("home-products-grid-korban");
			const indexId = this.products.findIndex(function (o) {
				return o.animationId === id;
			});
			for (let i = 0; i < elements.length; i++) {
				if (this.$route.name === "products" || this.$route.name === "home") {
					if (indexId == 0) {
						elements[1].style.setProperty("--opacity", 0.85);
						elements[2].style.setProperty("--opacity", 0.85);
					} else if (indexId == 1) {
						elements[0].style.setProperty("--opacity", 0.85);
						elements[2].style.setProperty("--opacity", 0.85);
					} else if (indexId == 2) {
						elements[0].style.setProperty("--opacity", 0.85);
						elements[1].style.setProperty("--opacity", 0.85);
					} else if (indexId == 3) {
						elements[4].style.setProperty("--opacity", 0.85);
						elements[5].style.setProperty("--opacity", 0.85);
					} else if (indexId == 4) {
						elements[3].style.setProperty("--opacity", 0.85);
						elements[5].style.setProperty("--opacity", 0.85);
					} else if (indexId == 5) {
						elements[3].style.setProperty("--opacity", 0.85);
						elements[4].style.setProperty("--opacity", 0.85);
					}
				} else {
					elements[i].style.setProperty("--opacity", 0.85);
					document.getElementById(id).style.setProperty("--opacity", 0);
				}
			}
			document.getElementById(id).style.filter = "brightness(100%)";
		},
		unhover() {
			let elements = document.getElementsByClassName("home-products-grid-korban");
			for (let i = 0; i < elements.length; i++) {
				elements[i].style.setProperty("--opacity", 0);
			}
		},
	},
	computed: {
		screenWidth() {
			return this.$store.state.screenWidth;
		},
		func() {
			let list = this.products;
			const route = this.$route.name;
			if (this.$route.name !== "products" && this.$route.name !== "home" && this.$route.name !== "specific") {
				list = list.filter((item) => {
					return item.id !== "specific" && item.id !== route && item.id !== "optimalization";
				});
			}
			if (this.$route.name === "specific") {
				list = list.filter((item) => {
					return item.id !== "ecrf" && item.id !== route && item.id !== "optimalization";
				});
			}
			if (this.$route.name === "optimalization") {
				list = list.filter((item) => {
					return item.id !== "ecrf" && item.id !== route && item.id !== "specific";
				});
			}
			return list;
		},
	},
	mounted() {
		if (this.screenWidth < 1200) {
			this.products.forEach((element) => {
				const video = document.getElementById(element.id);
				video?.play();
			});
		}
	},
};
</script>

<style lang="scss" scoped>
div {
	transition: all 300ms ease-out;
}

.home-products-grid-korban::after {
	content: "";
	background: #1901a1;
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1;
	opacity: var(--opacity, 0);
	filter: brightness(100%) contrast(190%) saturate(100%);
	transition: all 300ms ease-out;
}
</style>
