<template>
	<section class="about-workflow">
		<div class="about-workflow-grid">
			<div class="about-workflow-grid-container-blank"></div>
			<div class="about-workflow-grid-container">
				<div class="workflow-filler-right" data-delay=".5s"></div>
				<p class="b header-circle">
					<span class="anim"
						><span class="div-anim" data-delay=".2s">{{ $t("Workflow.krok") }} 1</span></span
					>
				</p>
				<h3 class="anim">
					<span class="div-anim" data-delay=".2s">{{ $t("Workflow.1.title") }}</span>
				</h3>
				<p class="anim">
					<span class="div-anim" data-delay=".2s">{{ $t("Workflow.1.content") }}</span>
				</p>
			</div>
			<div class="about-workflow-grid-container">
				<div class="workflow-filler-left" data-delay=".6s"></div>

				<p class="b">
					<span class="anim"
						><span class="div-anim" data-delay=".3s">{{ $t("Workflow.krok") }} 2</span></span
					>
				</p>
				<h3 class="anim">
					<span class="div-anim" data-delay=".3s">{{ $t("Workflow.2.title") }}</span>
				</h3>
				<p class="anim">
					<span class="div-anim" data-delay=".3s"> {{ $t("Workflow.2.content") }}</span>
				</p>
			</div>
			<div class="about-workflow-grid-container-blank"></div>
			<div class="about-workflow-grid-container-blank"></div>
			<div class="about-workflow-grid-container">
				<div class="workflow-filler-right" data-delay=".7s"></div>
				<p class="b">
					<span class="anim"
						><span class="div-anim" data-delay=".4s">{{ $t("Workflow.krok") }} 3</span></span
					>
				</p>
				<h3 class="anim">
					<span class="div-anim" data-delay=".4s">{{ $t("Workflow.3.title") }}</span>
				</h3>
				<p class="anim">
					<span class="div-anim" data-delay=".4s"> {{ $t("Workflow.3.content") }}</span>
				</p>
			</div>
			<div class="about-workflow-grid-container">
				<div class="workflow-filler-left" data-delay=".8s"></div>

				<p class="b">
					<span class="anim"
						><span class="div-anim" data-delay=".4s">{{ $t("Workflow.krok") }} 4</span></span
					>
				</p>
				<h3 class="anim">
					<span class="div-anim" data-delay=".4s">{{ $t("Workflow.4.title") }}</span>
				</h3>
				<p class="anim">
					<span class="div-anim" data-delay=".4s"> {{ $t("Workflow.4.content") }}</span>
				</p>
			</div>
			<div class="about-workflow-grid-container-blank"></div>
			<div class="about-workflow-grid-container-blank"></div>
			<div class="about-workflow-grid-container">
				<p class="b header-circle hehe">
					<span class="anim"
						><span class="div-anim" data-delay=".5s">{{ $t("Workflow.krok") }} 5</span></span
					>
				</p>
				<h3 class="anim">
					<span class="div-anim" data-delay=".5s">{{ $t("Workflow.5.title") }}</span>
				</h3>
				<p class="anim">
					<span class="div-anim" data-delay=".5s"> {{ $t("Workflow.5.content") }}</span>
				</p>
			</div>
			<div class="about-workflow-grid-container-blank"></div>
		</div>
	</section>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/config", "~@/assets/scss/media-queries", "~@/assets/scss/default-styles";

.about-workflow {
	position: relative;
	padding: 10px 0 0 0;
	&-grid {
		display: grid;
		grid-template-columns: repeat(2, 250px);
		justify-content: center;
		column-gap: 100px;
		// &:before {
		//     content: "";
		//     position: absolute;
		//     left: calc(50% - 5px);
		//     width: 10px;
		//     height: 900px;
		//     background-color: #e7e7e7;
		//     border-radius: 20px;
		// }
		&-container {
			position: relative;
			padding-bottom: 30px;
			.workflow-filler-right {
				position: absolute;
				left: -55px;
				height: 102%;
				width: 10px;
				background-color: #e7e7e7;
				border-radius: 20px;
				transform: scaleY(0);
				z-index: 1;
				transform-origin: 0px 0px;
				transition: transform ease-out;
			}
			.workflow-filler-left {
				position: absolute;
				right: -55px;
				height: 102%;
				width: 10px;
				background-color: #e7e7e7;
				border-radius: 20px;
				transform: scaleY(0);
				z-index: 1;
				transform-origin: 0px 0px;
				transition: transform ease-out;
			}

			&:nth-child(2n + 1) {
				text-align: right;
				h3 {
					margin-left: auto;
				}
			}
			&:nth-child(2),
			&:nth-child(6),
			&:nth-child(10) {
				.b {
					position: relative;
					opacity: 0;
					transition: all 1200ms ease;
					z-index: 1000;
					&:after {
						content: "";
						position: absolute;
						width: 20px;
						height: 20px;
						background-color: $primary-blue;
						border-radius: 100%;
						top: 50%;
						left: -60px;
						transform: translateY(-50%);
						z-index: 1000;
						opacity: inherit;
					}
				}
				.header-circle {
					&:after {
						width: 28px;
						height: 28px;
						border: 4px solid $light-blue;
						left: -68px;
						z-index: 1000;
					}
				}
			}
			&:nth-child(3),
			&:nth-child(7),
			&:nth-child(11) {
				// &:before {
				//     content: "";
				//     position: absolute;
				//     right: -55px;
				//     width: 10px;
				//     height: 250px;
				//     background-color: black;
				//     border-radius: 20px;
				// }
				.b {
					position: relative;
					opacity: 0;
					transition: all 1200ms ease;
					z-index: 1000;
					&:after {
						content: "";
						position: absolute;
						width: 20px;
						height: 20px;
						background-color: $primary-blue;
						border-radius: 100%;
						top: 50%;
						right: -60px;
						transform: translateY(-50%);
						z-index: 1000;
						opacity: inherit;
					}
				}
				.header-circle {
					&:after {
						width: 28px;
						height: 28px;
						border: 4px solid $light-blue;
						right: -68px;
						z-index: 1000;
					}
				}
			}
			h3 {
				color: $primary-blue;
				margin: 14px 0;
				width: auto;
			}
			p {
				color: $primary-blue;
				font-size: $fontSize14;
				line-height: 16.8px;
			}
		}
	}
	@include respond-below(md) {
		&-grid {
			column-gap: 50px;
			&-container {
				&:nth-child(2),
				&:nth-child(6),
				&:nth-child(10) {
					.b {
						&:after {
							left: -35px;
						}
					}
					.header-circle {
						&:after {
							left: -44px;
						}
					}
				}
				&:nth-child(3),
				&:nth-child(7),
				&:nth-child(11) {
					.b {
						&:after {
							right: -35px;
						}
					}
					.header-circle {
						&:after {
							right: -44px;
						}
					}
				}
			}
		}
	}
	@include respond-below(sm) {
		padding: 20px 0 0 0;
		&-grid {
			grid-template-columns: 50vw;
			grid-template-columns: 200px;
			justify-content: center;
			margin-left: 40px;
			&:before {
				display: none;
			}
			&-container {
				position: relative;
				margin-bottom: 50px;

				.workflow-filler-right {
					left: -46px;
					height: 130%;
					background-color: #e7e7e7;
				}
				.workflow-filler-left {
					left: -46px;
					height: 130%;
					background-color: #e7e7e7;
				}

				&:nth-child(2n + 1) {
					text-align: left;
					h3 {
						margin-left: 0;
					}
				}
				&-blank {
					display: none;
				}
				&:nth-child(2),
				&:nth-child(6),
				&:nth-child(10),
				&:nth-child(3),
				&:nth-child(7),
				&:nth-child(11) {
					.b {
						position: relative;
						font-family: "Roobert-SemiBold";
						// &:before {
						// 	content: "";
						// 	position: absolute;
						// 	left: -46px;
						// 	width: 10px;
						// 	height: 270px;
						// 	background-color: #e7e7e7;
						// 	z-index: 1;
						// }
						&:after {
							content: "";
							position: absolute;
							width: 20px;
							height: 20px;
							background-color: $primary-blue;
							border-radius: 100%;
							top: 50%;
							left: -51px;
							transform: translateY(-50%);
							z-index: 1000;
						}
					}
					.header-circle {
						&:after {
							width: 28px;
							height: 28px;
							border: 4px solid $light-blue;
							left: -59px;
						}
					}
					.hehe {
						&:before {
							display: none;
						}
					}
				}
				h3 {
					margin: 11px 0 14px 0;
					width: auto;
					font-size: $fontSize30;
					line-height: 29px;
				}
				p {
					color: $primary-blue;
					font-size: $fontSize15;
					line-height: 15px;
				}
			}
		}
	}
}
</style>
