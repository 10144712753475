<template>
	<section class="privacy-policy">
		<h1>Polityka prywatności</h1>
		<div class="privacy-policy-grid">
			<div class="privacy-policy-grid-container">
				<h2>OGÓLNA KLAUZULA INFORMACYJNA</h2>
				<ul class="list-outside">
					<li>
						1. NASZ CEL
						<ul class="list-inside">
							<li>
								1.1. Przedmiotowa ogólna klauzula informacyjna to zbiór zasad, których celem jest poinformowanie Pana/Pani o
								aspektach procesu odnoszącego się do pozyskania, przetwarzania oraz zabezpieczenia danych osobowych.
							</li>
							<li>
								1.2. Na podstawie art. 13 i 14 Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia
								2016 roku w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego
								przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (Dz. Urz. UE. L 2016 Nr 119 str.1), zwanego dalej RODO,
								informujemy, iż:
							</li>
						</ul>
					</li>
					<li>
						2. ADMINISTRATOR DANYCH OSOBOWYCH
						<ul class="list-inside">
							<li>
								2.1. Administratorem Pana/Pani danych osobowych jest E-Trust Sp. z o. o. z siedzibą w Warszawie, zwana dalej:
								"Administratorem".
							</li>
							<li>
								2.2. Z Administratorem może Pan/Pani skontaktować się bezpośrednio pisząc na adres: ul. Józefa Sowińskiego 53C
								lok. 15, 01-105 Warszawa lub wysyłając wiadomość elektroniczną na adres e-mail: rodo@etrust.pl.
							</li>
						</ul>
					</li>
					<li>
						3. ZARZĄDZANIE BEZPIECZEŃSTWEM:
						<ul class="list-inside">
							<li>
								3.1. Administrator – uwzględniając stan wiedzy technicznej, koszt wdrażania oraz charakter, zakres, kontekst i
								cele przetwarzania oraz ryzyko naruszenia praw lub wolności osób fizycznych o różnym prawdopodobieństwie
								wystąpienia i wadze zagrożenia – stosuje odpowiednie środki techniczne i organizacyjne zapewniające ochronę
								przetwarzanych danych osobowych odpowiednią do zagrożeń oraz kategorii danych objętych ochroną, a w szczególności
								zabezpiecza dane przed ich udostępnieniem osobom nieupoważnionym, zabraniem przez osobę nieuprawnioną,
								przetwarzaniem z naruszeniem obowiązujących przepisów oraz zmianą, utratą, uszkodzeniem lub zniszczeniem.
							</li>
						</ul>
					</li>
					<li>
						4. PRZETWARZANIE DANYCH OSOBOWYCH OSÓB KONTAKTUJĄCYCH SIĘ Z ETRUST SP. Z O. O. ZA POŚREDNICTWEM KORESPONDENCJI
						ELEKTRONICZNEJ LUB TELEFONICZNIE:
						<ul class="list-inside">
							<li>
								4.1. Cel przetwarzania danych osobowych: Dane osobowe, które Pan/Pani podaje kontaktując się z nami, przetwarzamy
								w celu udzielenia odpowiedzi na postawione pytania, ewentualnie w celu dochodzenia roszczeń i obrony przed
								roszczeniami.
							</li>
							<li>
								4.2. Jakie dane przetwarzamy: W przypadku kontaktu przetwarzamy następujące Pana/Pani dane osobowe: adres e-mail,
								imię i nazwisko, ewentualnie numer telefonu, stanowisko.
							</li>
							<li>
								4.3. Podstawa prawna przetwarzania danych: Podstawą prawną uprawniającą nas do przetwarzania Pana/Pani danych
								osobowych jest nasz prawnie uzasadniony interes (art. 6 ust. 1 lit. f RODO) – polegający na komunikacji z osobami
								zainteresowanymi naszą ofertą oraz możliwość ustalenia, dochodzenia lub obrony przed ewentualnymi roszczeniami.
							</li>
							<li>
								4.4. Okres przechowywania danych: Pana/Pani dane będą przetwarzane przez okres nie dłuższy, niż jest to konieczne
								do udzielenia Panu/Pani odpowiedzi, po tym czasie mogą być przetwarzane przez okres przedawnienia ewentualnych
								roszczeń lub do czasu wniesienia skutecznego sprzeciwu.
							</li>
							<li>
								4.5. Dobrowolność podania danych osobowych: W przypadku kontaktu z nami, podanie przez Pana/Panią danych jest
								dobrowolne, ale konieczne do tego, żeby odpowiedzieć na postawione pytanie.
							</li>
						</ul>
					</li>
				</ul>
			</div>
			<div class="privacy-policy-grid-container">
				<ul class="list-outside">
					<li>
						5. PRZETWARZANIE DANYCH OSOBOWYCH KONTRAHENTÓW WSPÓŁPRACUJĄCYCH Z ETRUST SP. Z O. O. ORAZ OSÓB WYZNACZONYCH PRZEZ
						KONTRAHENTÓW DO KONTAKTU, JAK RÓWNIEŻ PRZETWARZNIE DANYCH OSOBOWYCH ZLECENIOBIORCÓW:
						<ul class="list-inside">
							<li>
								5.1. W sytuacji, w której dane osobowe są przetwarzane w związku z zawarciem i realizacją danej umowy
								Administrator przekazuje osobie, której dane dotyczą, szczegółowe informacje odnoszące się do przetwarzania jej
								danych osobowych w terminach i na zasadach określonych w RODO. Niezależnie od powyższego, poniżej przedstawiamy
								ogólne informację dotyczące przetwarzania Pana/Pani danych osobowych w ww. przypadkach:
							</li>
							<li>
								5.2. Cel przetwarzania danych osobowych: Pan/Pani dane osobowe przetwarzamy w celu zawarcia i realizacji umowy,
								ewentualnie w celu dochodzenia roszczeń i obrony przed roszczeniami.
							</li>
							<li>
								5.3. Jakie dane przetwarzamy: W przypadku zawarcia i realizacji umowy przetwarzamy następujące Pana/Pani osobowe:
								adres e-mail, imię i nazwisko, numer telefonu, zaś w przypadku kontrahentów prowadzących jednoosobową działalność
								gospodarczą także adres zamieszkania, nr PESEL, nr rachunku bankowego, natomiast w przypadku zleceniobiorców
								nieprowadzących działalności gospodarczej dodatkowo dane niezbędne do odprowadzenia należnego podatku dochodowego
								oraz składek na ubezpieczenie społeczne i zdrowotne.
							</li>
							<li>
								5.4. Podstawa prawna przetwarzania danych: Podstawą prawną uprawniającą nas do przetwarzania Pana/Pani danych
								osobowych jest:
								<ul class="list-inside-checkbox">
									<li>
										5.4.1. art. 6 ust. 1 lit. b) RODO – przetwarzanie jest niezbędne do wykonania Umowy, której Pan/Pani jest
										stroną;
									</li>
									<li>
										5.4.2. art. 6 ust. 1 lit. c) RODO – przetwarzanie jest niezbędne do wypełnienia obowiązków prawnych ciążących
										na Administratorze związanych m.in. z przepisami podatkowymi, przepisami dot. systemu ubezpieczeń społecznych
										oraz przepisami o rachunkowości;
									</li>
									<li>
										5.4.3. art. 6 ust. 1 lit. c) RODO i 9 ust. 2 lit. b) RODO - przetwarzanie jest niezbędne do wypełnienia
										obowiązków prawnych ciążących na Administratorze związanych przepisami dot. systemu ubezpieczeń społecznych;
									</li>
									<li>
										5.4.4. art. 6 ust. 1 lit. f) RODO. Prawnie uzasadnionym interesem Administratora jest możliwość ustalenia,
										dochodzenia lub obrony przed ewentualnymi roszczeniami w przypadku powstania sporu dotyczącego Umowy, a także
										kontakt ze Kontrahentem, osobą wskazaną przez Kontrahenta do kontaktu, Zleceniodawcą.
									</li>
								</ul>
							</li>
							<li>
								5.5. Okres przechowywania danych: Pana/Pani dane osobowe będą przetwarzane przez okres wymagany przepisami prawa,
								przez okres trwania Umowy oraz przez okres przedawnienia ewentualnych roszczeń wynikających z Umowy, a także do
								czasu wniesienia przez Pana/Panią skutecznego sprzeciwu, w sytuacji, w której podstawa prawna przetwarzania
								została oparta o prawnie uzasadniony interes Administratora.
							</li>
							<li>
								5.6. Dobrowolność podania danych osobowych: Podanie danych osobowych jest dobrowolne, ale niezbędne do zawarcia i
								wykonania Umowy.
							</li>
						</ul>
					</li>
					<li>
						6. PODMIOTY, KTÓRYM UDOSTĘPNIANE SĄ DANE OSOBOWE:
						<ul class="list-inside">
							<li>
								6.1. Każdorazowo katalog odbiorców Pana/Pani danych osobowych przetwarzanych przez Administratora wynika z zakresu
								i rodzaju relacji prawnej jaka łączy Pana/Panią z ETRUST Sp. z o. o. oraz z przepisów prawa. W związku z powyższym
								Odbiorcami Pana/Pani danych osobowych mogą być:
								<ul class="list-inside-checkbox">
									<li>
										6.1.1. podmioty świadczące na rzecz Administratora usługi księgowe, badania jakości obsługi, dochodzenia
										należności, usługi prawne, doradcze, analityczne;
									</li>
									<li>6.1.2. dostawcy systemów informatycznych oraz usług IT;</li>
									<li>6.1.3. podmioty świadczące usługi zarządzania projektami;</li>
									<li>6.1.4. operatorzy pocztowi i kurierzy;</li>
									<li>6.1.5. organy uprawnione do otrzymania danych osobowych na podstawie przepisów prawa.</li>
								</ul>
							</li>
						</ul>
					</li>
					<li>
						7. PRZEKAZYWANIE DANYCH POZA EOG:
						<ul class="list-inside">
							<li>7.1. Pana/Pani dane osobowe nie będą przekazywane do państwa trzeciego</li>
						</ul>
					</li>
					<li>
						8. ZAUTOMATYZOWANE PODEJMOWANIE DECYZJI:
						<ul class="list-inside">
							<li>8.1. Pana/Pani dane osobowe nie będą przetwarzane w sposób zautomatyzowany, w tym nie będą profilowane.</li>
						</ul>
					</li>
					<li>
						9. PRAWA OSÓB, KTÓRYCH DANE DOTYCZĄ:
						<ul class="list-inside">
							<li>
								9.1. Przysługuje Panu/Pani prawo dostępu do treści danych osobowych oraz ich poprawiania, sprostowania oraz do
								ograniczenia przetwarzania.
							</li>
							<li>
								9.2. Przysługuje Panu/Pani prawo do wniesienia sprzeciwu wobec przetwarzania danych osobowych, zgodnie z treścią
								art. 21 RODO.
							</li>
							<li>
								9.3. W sytuacji, w której Pana/Pani dane osobowe będą przetwarzane na podstawie art. 6 ust. 1 lit. c i f) RODO nie
								przysługuje Panu/Pani prawo do przenoszenia danych osobowych.
							</li>
							<li>
								9.4. W sytuacji, w której Pana/Pani dane osobowe będą przetwarzane na podstawie art. 6 ust. 1 lit. f) RODO w
								zakresie dotyczących ustalenia, dochodzenia lub obrony roszczeń, a także w sytuacji, w której przetwarzanie będzie
								odbywać się na podstawie art. 6 ust. 1 lit. c) RODO nie przysługuje Panu/Pani prawo do ich usunięcia
							</li>
							<li>
								9.5. W przypadku powzięcia informacji o niezgodnym z prawem przetwarzaniu danych osobowych w E-Trust Sp. z o. o. z
								siedzibą w Warszawie przysługuje Panu/Pani prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych.
							</li>
						</ul>
					</li>
				</ul>
			</div>
		</div>
	</section>
</template>
