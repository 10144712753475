<template>
    <div class="products-slider" id="gallerySlider">
        <transition :name="changeDirection">
            <div class="slide" v-if="slideIndex === 0">
                <img src="~@/assets/images/scr/hospanel.png" alt="" />
            </div>
        </transition>
        <transition :name="changeDirection">
            <div class="slide" v-if="slideIndex === 1">
                <img src="~@/assets/images/scr/projekt-ecrf.png" alt="" />
            </div>
        </transition>
        <transition :name="changeDirection">
            <div class="slide" v-if="slideIndex === 2">
                <img src="~@/assets/images/scr/projekt-sorban.png" alt="" />
            </div>
        </transition>
        <transition :name="changeDirection">
            <div class="slide" v-if="slideIndex === 3">
                <img src="~@/assets/images/scr/radban.png" alt="" />
            </div>
        </transition>
    </div>
    <div class="dots">
        <span class="dot" :class="slideIndex === 0 ? 'active' : null" @click="sliderNav(0)"></span>
        <span class="dot" :class="slideIndex === 1 ? 'active' : null" @click="sliderNav(1)"></span>
        <span class="dot" :class="slideIndex === 2 ? 'active' : null" @click="sliderNav(2)"></span>
        <span class="dot" :class="slideIndex === 3 ? 'active' : null" @click="sliderNav(3)"></span>
    </div>
</template>

<script>
export default {
    data() {
        return {
            slideIndex: 0,
            galleryInterval: null,
            changeDirection: "left",
        };
    },
    mounted() {
        this.galleryTimeout();
        this.changeSlideIndex();

        let touchstartX = 0;
        let touchendX = 0;

        const slider = document.getElementById("gallerySlider");

        slider.addEventListener("touchstart", (e) => {
            touchstartX = e.changedTouches[0].screenX;
        });

        slider.addEventListener("touchend", (e) => {
            touchendX = e.changedTouches[0].screenX;
            if (touchendX < touchstartX && touchstartX - touchendX > 80) {
                this.changeDirection = "left";
                if (this.slideIndex < 3) {
                    this.slideIndex++;
                } else {
                    this.slideIndex = 0;
                }
                clearInterval(this.galleryInterval);
                this.galleryTimeout();
            }
            if (touchendX > touchstartX && touchendX - touchstartX > 80) {
                this.changeDirection = "right";
                if (this.slideIndex === 0) {
                    this.slideIndex = 3;
                } else {
                    this.slideIndex--;
                }
                clearInterval(this.galleryInterval);
                this.galleryTimeout();
            }
        });
    },
    methods: {
        galleryTimeout() {
            this.galleryInterval = setInterval(
                function () {
                    this.changeSlideIndex();
                }.bind(this),
                5000
            );
        },
        changeSlideIndex() {
            this.changeDirection = "left";

            if (this.slideIndex < 3) {
                this.slideIndex++;
            } else {
                this.slideIndex = 0;
            }
        },
        sliderNav(id) {
            this.slideIndex = id;
            clearInterval(this.galleryInterval);
            this.galleryTimeout();
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/config", "~@/assets/scss/media-queries", "~@/assets/scss/default-styles";

.dots {
    text-align: center;
    margin-top: 20px;
    .dot {
        cursor: pointer;
        height: 10px;
        width: 10px;
        margin: 0 2px;
        background-color: $primary-blue;
        border-radius: 50%;
        display: inline-block;
        transition: background-color 0.6s ease;
    }
    .active {
        background-color: $light-blue;
    }
}

.products-slider {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: calc(100% - 30px);
    .slide {
        width: inherit;
        height: inherit;
        position: absolute;
        img {
            width: inherit;
            height: inherit;
            object-fit: contain;
        }
    }
}

.left-enter-from {
    transform: translateX(100%);
    opacity: 0;
}
.left-enter-active {
    transition: all 0.8s ease-in-out;
}
.left-enter-to {
    transform: translateX(0);
    opacity: 1;
}
.left-leave-from {
    transform: translateX(0);
    opacity: 1;
}
.left-leave-active {
    transition: all 0.8s ease-in-out;
}
.left-leave-to {
    transform: translateX(-100%);
    opacity: 0;
}

.right-enter-from {
    transform: translateX(-100%);
    opacity: 0;
}
.right-enter-active {
    transition: all 0.8s ease-in-out;
}
.right-enter-to {
    transform: translateX(0);
    opacity: 1;
}
.right-leave-from {
    transform: translateX(0);
    opacity: 1;
}
.right-leave-active {
    transition: all 0.8s ease-in-out;
}
.right-leave-to {
    transform: translateX(100%);
    opacity: 0;
}
</style>
