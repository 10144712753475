<template>
	<section class="about-how-we-work">
		<h1>{{ $t("AboutUsHowWeWork.jak pracujemy") }}</h1>
		<h2>
			{{ $t("AboutUsHowWeWork.najwazniejsze") }}<br class="mobile-br" />
			{{ $t("AboutUsHowWeWork.aby") }}<br />{{ $t("AboutUsHowWeWork.i") }}
		</h2>
	</section>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/config", "~@/assets/scss/media-queries", "~@/assets/scss/default-styles";
.about-how-we-work {
	padding-top: 100px;
}
h1 {
	color: $primary-blue;
	text-align: center;
}
h2 {
	color: $primary-blue;
	width: auto;
	text-align: center;
	margin: 37px auto 110px auto;
}
@include respond-below(md) {
	.about-how-we-work {
		padding-top: 0px;
	}
	h2 {
		width: 85vw;
	}
}
@include respond-below(sm) {
	h1 {
		letter-spacing: -1.12px;
	}
	h2 {
		width: 100%;
		margin: 21px auto 27px auto;
	}
}
</style>
