<template>
    <section class="contact-container">
        <div class="contact-container-welcome-image"></div>
        <div class="contact-container-welcome-text">
            <h1 class="contact-h1"><span v-html="$t('contact.banner.title')"></span></h1>
            <p><span v-html="$t('contact.banner.content')"></span></p>
        </div>
    </section>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/config", "~@/assets/scss/media-queries",
    "~@/assets/scss/default-styles";

.contact-container {
    display: flex;
    height: 80vh;
    &-welcome-image {
        width: 50%;
        background-image: url("~@/assets/images/teamcontact.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    &-welcome-text {
        width: 50%;
        background-color: $light-bluev2;
        padding: 98px 41px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        h1 {
            color: #ebebed;
            font-size: 56px;
            line-height: 50.3px;
            margin-bottom: 45px;
            width: 360px;
        }
    }
    @include respond-below(xl) {
        &-welcome-text {
            p {
                font-size: $fontSize17;
                line-height: normal;
            }
        }
    }
    @include respond-below(lg) {
        width: 100vw;
        height: auto;
        flex-direction: column;
        &-welcome-image {
            width: 100%;
            height: 90vw;
        }
        &-welcome-text {
            width: 100vw;
            height: auto;
            padding: 70px 30px;
            display: flex;
            flex-direction: column;
            justify-content: start;
            letter-spacing: -2.24px;
            h1 {
                font-size: $fontSize48;
                line-height: normal;
                letter-spacing: 0;
                .mobile-br {
                    display: block;
                }
            }
            h1,
            p {
                width: 85vw;
                margin-left: auto;
                margin-right: auto;
            }
            .desktop-br {
                display: block;
            }
        }
    }
    @include respond-below(sm) {
        h1 {
            font-size: $fontSize30;
            letter-spacing: -1.2px;
            line-height: 32px;
            margin: 0px 0 24px 0;
            width: 90%;
        }
        p {
            font-size: $fontSize16;
            letter-spacing: 0px;
            line-height: 19.2px;
            width: 100%;
        }
    }
}
</style>