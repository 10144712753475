<template>
	<dialog open>
		<i class="fa-solid fa-xmark" @click="$emit('close')"></i>
		<template v-if="$i18n.locale == 'pl-PL'">
			<video autoplay controls>
				<source src="~@/assets/videos/popup-video.mp4#t=0.1" type="video/mp4" />
			</video>
		</template>
		<template v-if="$i18n.locale == 'en-US'">
			<video autoplay controls>
				<source src="~@/assets/videos/popup-video-eng.mp4#t=0.1" type="video/mp4" />
			</video>
		</template>
		<!-- <button @click="$emit('close')">Close</button> -->
	</dialog>
</template>

<script>
export default {
	emits: ["close"],
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/config", "~@/assets/scss/media-queries", "~@/assets/scss/default-styles";
// ::-webkit-scrollbar {
//     display: none;
// }
i {
	position: absolute;
	top: 20px;
	right: 40px;
	color: #999;
	cursor: pointer;
	font-size: $fontSize32;
	z-index: 1000001;
	transition-duration: 300ms;
	&:hover {
		color: rgb(94, 94, 94);
	}
}

dialog {
	position: fixed;
	width: 100%;
	height: auto;
	z-index: 100000;
	border: none;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
	padding: 0;
	margin: 0;
	overflow: hidden;
	top: 0;
	background: rgba(0, 0, 0, 0.8);
	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
	@include respond-below(lg) {
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

video {
	object-fit: contain;
	width: 100vw;
	height: 100vh;
	@include respond-below(lg) {
		height: auto;
	}
}

button {
	border-radius: 27px;
	background-color: $primary-blue;
	border: none;
	color: #fff;
	text-align: left;
	font-size: $fontSize16;
	padding-left: 28px;
	width: 200px;
	height: 55px;
	transition: all 0.5s ease;
	cursor: pointer;
	display: inline-block;
	position: relative;
	&:hover {
		background-color: $light-blue;
		padding-left: 40px;
	}
	@include respond-below(lg) {
		text-align: center;
		padding-left: 0;
		&:hover {
			padding-left: 0;
		}
	}
	@include respond-below(sm) {
		font-size: $fontSize14;
	}
}
</style>
