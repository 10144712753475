<template>
	<section class="about-banner">
		<div class="about-banner-half"></div>
		<div class="about-banner-half">
			<p class="anim">
				<span class="div-anim" data-delay=".1s"> {{ $t("aboutUsBanner.nasza filozofia") }} </span>
			</p>

			<h1 class="anim">
				<div class="div-anim" data-delay=".1s">{{ $t("aboutUsBanner.chcemy") }}&nbsp;</div>
				<div class="div-anim" data-delay=".3s">{{ $t("aboutUsBanner.aby") }}</div>
				<br />
				<div class="div-anim" data-delay=".5s">{{ $t("aboutUsBanner.lepszy") }}</div>
				<br />
				<div class="div-anim" data-delay=".7s">{{ $t("aboutUsBanner.i") }}</div>
			</h1>
			<h2 class="anim">
				<div class="div-anim" data-delay=".5s">
					{{ $t("aboutUsBanner.znamy") }} <br class="mobile-br" />
					{{ $t("aboutUsBanner.od") }}<br class="desktop-br" />
					{{ $t("aboutUsBanner.wiemy") }} <br class="mobile-br" />{{ $t("aboutUsBanner.jest") }}<br />
					{{ $t("aboutUsBanner.mozemy") }} {{ $t("aboutUsBanner.zoptymalizowac") }} <br class="mobile-br" />
					{{ $t("aboutUsBanner.wdrozyc") }}<br class="desktop-br" />
					{{ $t("aboutUsBanner.dedykowane") }}<br class="mobile-br" />
					{{ $t("aboutUsBanner.informatyczne") }}
				</div>
			</h2>
			<p class="signature anim"><span class="div-anim" data-delay=".1s">Michal Kasiewicz</span></p>
		</div>
	</section>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/config", "~@/assets/scss/media-queries", "~@/assets/scss/default-styles";

.about-banner {
	height: 100vh;
	background-image: url("~@/assets/images/michal-kasiewicz-wide.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center top;
	display: flex;
	align-items: center;
	.about-banner-half {
		width: 50%;
	}
	p {
		color: $light-blue;
		font-size: $fontSize16;
	}
	.signature {
		font-size: $fontSize30;
		margin: 33px 0 0 0;
	}
	h1 {
		line-height: 71.75px;
		margin: 28px 0 37px 0;
		max-width: 600px;
	}
	h2 {
		line-height: 27.6px;
		width: auto;
	}
	@include respond-below(xl) {
		height: 85vh;
		background-position: 20% center;
		.about-banner-half {
			&:first-child {
				width: 45%;
			}
			&:nth-child(2) {
				width: 55%;
			}
		}
		h1 {
			font-size: $fontSize48;
			line-height: normal;
		}
		h2 {
			font-size: $fontSize20;
			line-height: normal;
		}
	}
	@include respond-below(lg) {
		background: none;
		height: auto;
		flex-direction: column;
		.about-banner-half:first-child {
			width: 100%;
			height: 55vw;
			background-image: url("~@/assets/images/michal-kasiewicz-wide.jpg");
			background-size: 100%;
			background-position: center;
		}
		.about-banner-half:nth-child(2) {
			background-color: $primary-blue;
			width: 100vw;
			height: auto;
			padding: 48px 0;
			h1 {
				font-size: $fontSize48;
				line-height: normal;
			}
			h2 {
				font-size: $fontSize16;
				line-height: normal;
				.desktop-br {
					display: none;
				}
				.mobile-br {
					display: block;
				}
			}
			p,
			h1,
			h2 {
				max-width: 85vw;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
	@include respond-below(sm) {
		.about-banner-half:nth-child(2) {
			padding: 28px 0 56px 0;
			p {
				font-size: $fontSize15;
			}
			.signature {
				font-size: 30.25px;
				margin: 25px auto 0 auto;
			}
			h1 {
				line-height: 32.95px;
				letter-spacing: -1.2px;
				margin: 20px auto 22px auto;
				max-width: 85vw;
				font-size: $fontSize30;
			}
			h2 {
				font-size: $fontSize15;
				letter-spacing: -0.3px;
				line-height: 18px;
				max-width: 85vw;
			}
		}
	}
}
</style>
